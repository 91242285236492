exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-experiments-concepts-js": () => import("./../../../src/pages/experiments/concepts.js" /* webpackChunkName: "component---src-pages-experiments-concepts-js" */),
  "component---src-pages-experiments-dailyui-js": () => import("./../../../src/pages/experiments/dailyui.js" /* webpackChunkName: "component---src-pages-experiments-dailyui-js" */),
  "component---src-pages-experiments-js": () => import("./../../../src/pages/experiments.js" /* webpackChunkName: "component---src-pages-experiments-js" */),
  "component---src-pages-freebies-ecstasy-js": () => import("./../../../src/pages/freebies/ecstasy.js" /* webpackChunkName: "component---src-pages-freebies-ecstasy-js" */),
  "component---src-pages-freebies-hotelify-js": () => import("./../../../src/pages/freebies/hotelify.js" /* webpackChunkName: "component---src-pages-freebies-hotelify-js" */),
  "component---src-pages-freebies-js": () => import("./../../../src/pages/freebies.js" /* webpackChunkName: "component---src-pages-freebies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-templates-portfolio-work-js": () => import("./../../../src/templates/portfolio-work.js" /* webpackChunkName: "component---src-templates-portfolio-work-js" */)
}

